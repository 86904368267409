import React from 'react'
import {Link} from 'react-router-dom'
import ReactWOW from 'react-wow'
import lazySizes from 'lazysizes'
import lax from '../lax.js'
import Heading from './Heading'
import SlideshowA from './SlideshowA'
import SlideshowB from './SlideshowB'
import Footer from './Footer'

export default function VendoServices() {
	React.useEffect(() => {
		window.scrollTo(0,0);
		lax.setup() // init  

		const updateLax = () => {
			lax.update(window.scrollY)
			window.requestAnimationFrame(updateLax)
		}

		window.requestAnimationFrame(updateLax)				
	}, []);
	return ( 
	<div>
		<div id="UserInterfaceDesignAndFrontEndDevelopment" className="main animate__animated animate__fadeIn animate__delay-14s animate__slower">
			<div id="vendo">
				<Heading/>
				<div className="list-container">
					<ul className="floating-list">
						<li id="vendo-comparison">
						</li>
						<li id="ux a">
						</li>
						<li id="ux b">
						</li>
						<li id="userflow">
						</li>
						<li id="vendo-comparison">
						</li>
						<li id="vendo-form-m">
						</li>								
						<li id="vendo-form-k">
						</li>
						<li id="vendo-form-j">
						</li>																	
						<li id="vendo-reporting-revenue">
							<img data-src="img/vendo-mobile-form-a-transparent-background.png" className=" floating-mobile a lazyload lax" alt="Digital Design"
							data-lax-translate-y="(vh*0.5) 360,(vh*-0.5) 0"
							data-lax-anchor="self"
							/>																			
							<div id="fish">
								<img src="img/fish.png"  className="lax baby lazyload" data-lax-preset="leftToRight linger" alt="Design"/>
								<img src="img/fish.png"  className="lax baby lazyload" data-lax-preset="leftToRight" alt="Design"/>
							</div>														
						</li>
						<li id="vendo-form-q">
						</li>								
						<li id="vendo-form-g">
							<img data-src="img/vendo-mobile-form-e-transparent-background.png" className=" floating-mobile b lazyload lax" alt="Digital Design"
							data-lax-translate-y="(vh*0.5) 360,(vh*-0.5) 0"
							data-lax-anchor="self"
							/>																																																
						</li>		
						<li id="vendo-form-h">
						</li>
						<li id="vendo-form-a">
						</li>
						<li id="vendo-form-b">
						</li>			
						<li id="vendo-reporting-interface">
						</li>		
						<li id="vendo-form-l">
						</li>																
						<li id="vendo-store">							
							<img data-src="img/vendo-mobile-form-c-transparent-background.png" className=" floating-mobile c lazyload lax" alt="Digital Design"
							data-lax-translate-y="(vh*0.5) 360,(vh*-0.5) 0"
							data-lax-anchor="self"
							/>
						</li>						
						<li id="vendo-form-d">
						</li>
						<li id="vendo-form-f">		
						</li>
						<li id="vendo-form-i">
							<img data-src="img/vendo-mobile-form-b-transparent-background.png" className=" floating-mobile d lazyload lax" alt="Digital Design"
							data-lax-translate-y="(vh*0.5) 360,(vh*-0.5) 0"
							data-lax-anchor="self"
							/>																									
						</li>							
						<li id="vendo-form-o">								
						</li>		
						<li id="vendo-form-p">
						</li>							
						<li id="vendo-form-n">
							<img data-src="img/vendo-mobile-form-d-transparent-background.png" className=" floating-mobile e lazyload lax" alt="Digital Design"
							data-lax-translate-y="(vh*0.5) 360,(vh*-0.5) 0"
							data-lax-anchor="self"
							/>																									
						</li>																																
						<li id="vendo-form-e">									
						</li>
						<li className="mobile smaller-screen">
						</li>								
						<li className="mobile smaller-screen">
						</li>		
						<li className="mobile smaller-screen">
						</li>			
					</ul>
				</div>	
				<div className="golden main-description-wrapper animate__animated animate__fadeIn animate__slower">
					<div className="golden-left animate__animated animate__fadeIn animate__delay-06s animate__slow">
						<div className="project-info">
							<div className="logo">
								<img src="img/logos/vendo.svg" className="lazyload" alt="Vendo Services logo"/>							
							</div>
							<span className="duration">2007 - 2018 | 2020</span>			
							<span className="role">#Financial Technology</span>
							<span className="role">UI/UX Design | Web Design</span>		
							<span className="role">Front-End Development</span>
							<span className="role">Visual & Graphic Design</span>
							<span className="role">In-house employee</span>
						</div>
					</div>
					<div className="golden-right animate__animated animate__fadeIn animate__delay-07s animate__slow">
						<div className="description">	
							<h3>
								Vendo Services
							</h3>
							<p>
								Swiss-Spanish leader in Digital Payments that grow the revenue of its Clients by using data and AI, with dynamic pricing in 23 languages and local currency.
							</p>
							{/* <p>
								As the sole designer during half of my 11+ years with the company, I was in charge of designing and developing personalized and customizable User interfaces for their Products, <span className="highlight"> providing a consistent experience with a strong focus on increasing the Conversion rate. </span>Personalizing the experiences proved to sell more.</p> */}
								<p>
								As the sole designer during half of my 11+ years with the company, I designed and developed personalized and customizable User interfaces for their Products. Our goal was to provide  <span className="highlight"> a consistent experience with a strong focus on increasing the Conversion rate. </span>Personalizing the experiences proved to sell more.</p>


								

							</div>						
						</div>						
					</div>				
					<div className="golden user-experience-research animate__animated animate__fadeIn animate__slower">
						<div className="golden-left animate__animated animate__fadeIn animate__delay-06s animate__slow">
						</div>
						<div className="golden-right animate__animated animate__fadeIn animate__delay-07s animate__slow">
							<div className="description">	
								<p className="paragraph-block">
									<span className="category-sub-heading">The Clients: </span>
									(Merchants) Digital content providers that
								</p>										
								<ul>
									<li>
										<p className="secondary-paragraph">
											wanted to maximize their revenue by offering their end-Users the best Product and Payment experiences.
										</p>																							
									</li>
									<li>
										<p className="secondary-paragraph">
											needed a Digital Payment Solution that supports their brand image and design efforts, providing consistency, and a successful User experience.
										</p>
									</li>
									<li>
										<p className="secondary-paragraph">
											wanted to customize parts (sometimes the whole) of their Product and Payment experiences by using their content and Marketing copy.
										</p>												
									</li>
									<li>
										<p className="secondary-paragraph">
											needed to receive the right information to make smarter business decisions.
										</p>												
									</li>
								</ul>																				
								<p className="paragraph-block wildcard">
									<span className="category-sub-heading">The Customers: </span>
									Digital content end-Users that
								</p>
								<ul>
									<li>
										<p className="secondary-paragraph">
											needed to be guided and stimulated to complete successful transactions to access their favorite digital content.
										</p>												
									</li>
									<li>
										<p className="secondary-paragraph">
											needed to be able to trust the Payment process with their personal data.
										</p>																						
									</li>
								</ul>
								<h3>
									User Experience Research
								</h3>
								<p>
									The challenge was to identify the different types of Users and understand their journeys, how they interact with the Product, their needs, frustrations, and behavior. The research was part of the project of expanding the services of Vendo to end-Users (B2C) and small businesses. 
								</p>																
							</div>						
						</div>						
					</div>								
					<ul className="animate__animated animate__fadeIn animate__slower">
						<li className="three-columns ux a">
							<div className="info-block">
								<p className="long-text">
									<span className="product-name">Personas</span>
									<span className="work">Credits: María Mallorquín, Isabel Serban, Alberto López. Design: Xtensio</span>
								</p>
							</div>							
							<div id="list-inner-wrapper">			
								<div className="one-third">
									<noscript>
										<img src="img/vendo-personas-john.jpg" className="lazyload" alt="Vendo Personas"/>																		
									</noscript>						
									<img src="img/vendo-personas-john.jpg" className="lazyload" alt="Vendo Personas"/>																		
								</div>
								<div className="one-third">
									<noscript>
										<img src="img/vendo-personas-alicia.jpg" className="lazyload" alt="Vendo Personas"/>																		
									</noscript>						
									<img src="img/vendo-personas-alicia.jpg" className="lazyload" alt="Vendo Personas"/>																		
								</div>
								<div className="one-third">
									<noscript>
										<img src="img/vendo-personas-danielle.jpg" className="lazyload" alt="Vendo Personas"/>																		
									</noscript>						
									<img src="img/vendo-personas-danielle.jpg" className="lazyload" alt="Vendo Personas"/>																		
								</div>
							</div>									
						</li>						
						<li className="two-columns ux b">
							<div id="list-inner-wrapper">			
								<div className="fifty right">
									<noscript>
										<img src="img/vendo-personas-eva.jpg" className="lazyload" alt="Vendo Personas"/>																		
									</noscript>						
									<img src="img/vendo-personas-eva.jpg" className="lazyload" alt="Vendo Personas"/>																		
								</div>
								<div className="fifty left">
									<noscript>
										<img src="img/vendo-personas-eric.jpg" className="lazyload" alt="Vendo Personas"/>																		
									</noscript>						
									<img src="img/vendo-personas-eric.jpg" className="lazyload" alt="Vendo Personas"/>																		
								</div>																
							</div>									
						</li>
						<li className="two-columns ux user-journeys">
							<div className="info-block">
								<p className="long-text">
									<span className="product-name">User Journey Maps</span>
									<span className="work">The insights and opportunities identified in this research are private to Vendo Services and have been omitted</span>
								</p>
							</div>							
							<div id="list-inner-wrapper">			
								<div className="fifty right">
									<noscript>
										<img src="img/eva-journey.png" className="lazyload" alt="Vendo User Journey"/>																		
									</noscript>
									<img data-src="img/eva-journey.png" className="lazyload" alt="Vendo User Journey"
									data-srcset="img/eva-journey.png 1400w, img/eva-journey-s.png 900w"
									data-sizes="(min-width: 900px) 1400px, 100vw"
									/>
								</div>
								<div className="fifty left">
									<noscript>
										<img src="img/eric-journey.png" className="lazyload" alt="Vendo User Journey"/>																		
									</noscript>
									<img data-src="img/vendo-userflow.png" className="lazyload" alt="Vendo User Journey"
									data-srcset="img/eric-journey.png 1400w, img/eric-journey-s.png 900w"
									data-sizes="(min-width: 900px) 1400px, 100vw"
									/>
								</div>																
							</div>									
						</li>
						<li className="userflow">								
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">User Flow</span>
									<span className="work">End-Users</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-userflow.png" className="lazyload" alt="Vendo User Flow"/>																		
							</noscript>
							<img data-src="img/vendo-userflow.png" className="lazyload" alt="Vendo User Flow"
							data-srcset="img/vendo-userflow.png 1425w, img/vendo-userflow-s.png 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
						</li>
					</ul>					
					< ReactWOW >						
					<div className="golden user-interface ui animate__animated animate__fadeIn animate__slower">
						<div className="golden-left animate__animated animate__fadeIn animate__delay-06s animate__slow">
							<div className="project-info">
								<div className="logo">
									<img src="img/logos/vendo.svg" className="lazyload" alt="Vendo Services logo"/>							
								</div>
								<span className="duration">2007 - 2018 | 2020</span>								
							</div>
						</div>
						<div className="golden-right animate__animated animate__fadeIn animate__delay-07s animate__slow">
							<div className="description">	
								<h3>
									User Interface Design
								</h3>
								<p className="wildcard-b">
									It was supported by research and analysis. The platform's AI tested the experiences, adjusting their visits according to Conversion goals, and learning what converts better. For multivariate testing, we (two Designers and one Analyst) started with a hypothesis and iterated to create stronger experiences.
								</p>
								<p>
									I regularly monitored what the competitors did with their interfaces. At times the Clients provided the designs, and I developed and integrated a pixel-perfect version into the platform.
								</p>																			
								<p>
									Vendo handles personal data and operates with high-security standards (like PCI DSS). Therefore, its environment can't be modified freely and easily to adapt to every Client's requirements. Developing User interfaces that satisfied the aesthetic and sometimes technical expectations of the Clients, without being able to control all the code, was challenging but fun.
								</p>
							</div>						
						</div>						
					</div>						
					< /ReactWOW >														
					<ul>
						<li id="vendo-comparison">
							<SlideshowB/>
						</li>				
						<li id="vendo-form-m">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Product Page</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>
							</div>														
							<noscript>
								<img src="img/vendo-form-m.jpg" alt="Web Development"/>
							</noscript>
							<img data-src="img/vendo-form-m.jpg" className="lazyload" alt="Web Development"
							data-srcset="img/vendo-form-m.jpg 1425w, img/vendo-form-m-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>

						</li>						
						<li id="vendo-form-k">
							<div className=" info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Design & Front-End Development</span>
								</p>
							</div>							
							<noscript>
								<img src="img/vendo-form-k.png" alt="Digital Design"/>
							</noscript>
							<img data-src="img/vendo-form-k.png" className="lazyload" alt="Digital Design"
							data-srcset="img/vendo-form-k.png 1425w, img/vendo-form-k-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
						</li>
						<li id="vendo-form-j">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Product Page</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Design & Front-End Development</span>
								</p>
							</div>							
							<noscript>
								<img src="img/vendo-form-j.jpg" alt="Digital Design"/>
							</noscript>
							<img data-src="img/vendo-form-j.jpg" className="lazyload" alt="Digital Design"
							data-srcset="img/vendo-form-j.jpg 1425w, img/vendo-form-j-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
						</li>
						<li className="mobile smaller-screen wildcard">
							<div className="info-block">
								<p>
									<span className="company-name">Adnovation</span>
									<span className="dash"> - </span>
									<span className="product-name">Product Form</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Design & Front-End Development</span>
								</p>
							</div>
							<noscript>
								<img src="img/adnovation-mobile-form-transparent-background.png" alt="Digital Design"/>
							</noscript>
							<div>							
								<img data-src="img/adnovation-mobile-form-transparent-background.png" className="lazyload" alt="Digital Design"/>
							</div>
						</li>								
						<li className="mobile smaller-screen">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Design & Front-End Development</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-mobile-form-a-transparent-background.png" alt="Digital Design"/>
							</noscript>
							<div>							
								<img data-src="img/vendo-mobile-form-a-transparent-background.png" className="lazyload" alt="Digital Design"/>
							</div>
						</li>		
						<li className="mobile smaller-screen">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Design & Front-End Development</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-mobile-form-b-transparent-background.png" alt="Digital Design"/>
							</noscript>
							<div>												
								<img data-src="img/vendo-mobile-form-b-transparent-background.png" className="lazyload" alt="Digital Design"/>
							</div>
						</li>														
						<li id="vendo-reporting-revenue">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Data Tools</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Design & Front-End Development</span>
								</p>
							</div>							
							<noscript>
								<img src="img/vendo-reporting-revenue.jpg" alt="Digital Design"/>
							</noscript>
							<img data-src="img/vendo-reporting-revenue.jpg" className="lazyload" alt="Digital Design"
							data-srcset="img/vendo-reporting-revenue.jpg 1425w, img/vendo-reporting-revenue-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
						</li>		
						<li id="vendo-form-q">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>			
							</div>
							<noscript>
								<img src="img/vendo-form-q.jpg" alt="Web Development"/>
							</noscript>
							<img data-src="img/vendo-form-q.jpg" className="lazyload" alt="Web Development"
							data-srcset="img/vendo-form-q.jpg 1425w, img/vendo-form-q-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
						</li>
						<li id="vendo-form-g">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Product Page</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Design & Front-End Development</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-form-g.jpg" alt="Digital Design"/>
							</noscript>
							<img data-src="img/vendo-form-g.jpg" className="lazyload" alt="Digital Design"
							data-srcset="img/vendo-form-g.jpg 1425w, img/vendo-form-g-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
						</li>
						<li id="vendo-form-h">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Product Page</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Design & Front-End Development</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-form-h.jpg" alt="Digital Design"/>
							</noscript>
							<img data-src="img/vendo-form-h.jpg" className="lazyload" alt="Digital Design"
							data-srcset="img/vendo-form-h.jpg 1425w, img/vendo-form-h-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
						</li>
						<li id="vendo-form-a">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Design & Front-End Development</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-form-a.png" alt="Digital Design"/>
							</noscript>
							<img data-src="img/vendo-form-a.png" className="lazyload" alt="Digital Design"
							data-srcset="img/vendo-form-a.png 1425w, img/vendo-form-a-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
						</li>
						<li id="vendo-form-b">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>			
							</div>
							<noscript>
								<img src="img/vendo-form-b.png" alt="Web Development"/>
							</noscript>
							<img data-src="img/vendo-form-b.png" className="lazyload" alt="Web Development"
							data-srcset="img/vendo-form-b.png 1425w, img/vendo-form-b-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
						</li>
						<li className="mobile smaller-screen">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-mobile-form-c-transparent-background.png" alt="Digital Design"/>
							</noscript>
							<div>							
								<img data-src="img/vendo-mobile-form-c-transparent-background.png" className="lazyload" alt="Digital Design"/>
							</div>
						</li>								
						<li className="mobile smaller-screen">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-mobile-form-d-transparent-background.png" alt="Digital Design"/>
							</noscript>
							<div>							
								<img data-src="img/vendo-mobile-form-d-transparent-background.png" className="lazyload" alt="Digital Design"/>
							</div>
						</li>		
						<li className="mobile smaller-screen">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-mobile-form-e-transparent-background.png" alt="Digital Design"/>
							</noscript>
							<div>												
								<img data-src="img/vendo-mobile-form-e-transparent-background.png" className="lazyload" alt="Digital Design"/>
							</div>
						</li>																		
						<li id="vendo-reporting-interface">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Data Tools</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Mock-up</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-reporting-interface.jpg" alt="UI Design"/>
							</noscript>
							<img data-src="img/vendo-reporting-interface.jpg" className="lazyload" alt="UI Design"
							data-srcset="img/vendo-reporting-interface.jpg 1398w, img/vendo-reporting-interface-s.jpg 900w"
							data-sizes="(min-width: 900px) 1398px, 100vw"
							/>
						</li>	
						<li id="vendo-form-l">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-form-l.png" alt="Web Development"/>
							</noscript>
							<img data-src="img/vendo-form-l.png" className="lazyload" alt="Web Development"
							data-srcset="img/vendo-form-l.png 1425w, img/vendo-form-l-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"										
							/>												
						</li>								
						<li id="vendo-store">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Vendo Store</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>
							</div>			
							<noscript>
								<img src="img/vendo-store.png" alt="Web Development"/>
							</noscript>
							<img data-src="img/vendo-store.png" className="lazyload" alt="Web Development"
							data-srcset="img/vendo-store.png 1024w, img/vendo-store-s.jpg 900w"
							data-sizes="(min-width: 900px) 1024px, 100vw"
							/>
							<div className="oldie-wrapper" id="oldie-store"><span className="oldie">Oldie but served its purpose</span></div>										
						</li>
						<li id="vendo-form-d">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-form-d.png" alt="Web Development"/>
							</noscript>
							<img data-src="img/vendo-form-d.png" className="lazyload" alt="Web Development"
							data-srcset="img/vendo-form-d.png 1425w, img/vendo-form-d-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"										
							/>								
						</li>
						<li id="vendo-form-f">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Design & Front-End Development</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-form-f.jpg" alt="Digital Design"/>
							</noscript>
							<img data-src="img/vendo-form-f.jpg" className="lazyload" alt="Digital Design"
							data-srcset="img/vendo-form-f.jpg 1425w, img/vendo-form-f-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
							<div className="oldie-wrapper"><span className="oldie">Oldie but made a lot of money</span></div>										
						</li>
						<li id="vendo-form-o">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>			
							</div>
							<noscript>
								<img src="img/vendo-form-o.jpg" alt="Web Development"/>
							</noscript>
							<img data-src="img/vendo-form-o.jpg" className="lazyload" alt="Web Development"
							data-srcset="img/vendo-form-o.jpg 1425w, img/vendo-form-o-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>			
						</li>	
						<li id="vendo-form-p">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>			
							</div>
							<noscript>
								<img src="img/vendo-form-p.jpg" alt="Web Development"/>
							</noscript>
							<img data-src="img/vendo-form-p.jpg" className="lazyload" alt="Web Development"
							data-srcset="img/vendo-form-p.jpg 1425w, img/vendo-form-p-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>						
						</li>							
						<li id="vendo-form-n">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">Front-End Development</span>
									<span className="not-my-design">* Client's Design</span>
								</p>		
							</div>
							<noscript>
								<img src="img/vendo-form-n.jpg" alt="Web Development"/>
							</noscript>
							<img data-src="img/vendo-form-n.jpg" className="lazyload" alt="Web Development"
							data-srcset="img/vendo-form-n.jpg 1425w, img/vendo-form-n-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
						</li>
						<li id="vendo-form-e">
							<div className="info-block">
								<p>
									<span className="company-name">Vendo Services</span>
									<span className="dash"> - </span>
									<span className="product-name">Payment Page</span>
									<span className="dash"> - </span>
									<span className="work">User Interface Design & Front-End Development</span>
								</p>
							</div>
							<noscript>
								<img src="img/vendo-form-e.jpg" alt="Digital Design"/>
							</noscript>
							<img data-src="img/vendo-form-e.jpg" className="lazyload" alt="Digital Design"
							data-srcset="img/vendo-form-e.jpg 1425w, img/vendo-form-e-s.jpg 900w"
							data-sizes="(min-width: 900px) 1425px, 100vw"
							/>
						</li>								
					</ul>						
				</div>
			</div>
		
			<Footer/>
				
			<Link className="next-project"to= '/hemp-tailor'>						
				Next project →
			</Link>
				
		</div>
		);
	}