import React from 'react'

export default function Heading() {
	return ( 
	<div id="heading-wrapper">
		<h2 className="animate__animated animate__fadeIn animate__delay-14s animate__slow">
			<span id="ui">
				<span>UI/UX</span>
				<span>Design
					<span className="and mobile"><img src="img/&.svg" alt="&"/></span>
				</span>
			</span>
			<span className="and"><img src="img/&.svg" alt="&amp;"/></span>
			<span id="fe">
				<span> Front-End Development</span>
			</span>
		</h2>		
	</div>
	);
}