import React from 'react';
import Slideshow from './Slideshow';

const data = [
'img/hemp-tailor-product-page-ui-before.jpg',
'img/hemp-tailor-product-page-ui-after.jpg',
]
function SlideshowA() {
	return (
	<div className="slideshow">
		<Slideshow images={data} withLoop={false} autoPlay={false}/>
	</div>
	);
}

export default SlideshowA;
