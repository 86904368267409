import React from 'react'
import './styles/App.scss'
import Header from './components/Header'
import UiUxAndFe from './components/UiUxAndFe'
import VendoServices from './components/VendoServices'
import HempTailor from './components/HempTailor'
import Adnovation from './components/Adnovation'
import Visual from './components/Visual'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

export default function App() {	
	
	return (		
	<Router>
		<div class="container">
			<Header/>
			<Switch>
				<Route path ='/' exact component={UiUxAndFe}/>
				<Route path ='/ui-ux-fe' component={UiUxAndFe}/>
				<Route path ='/visual' component={Visual}/>
				<Route path ='/vendo-services' component={VendoServices}/>
				<Route path ='/hemp-tailor' component={HempTailor}/>
				<Route path ='/adnovation' component={Adnovation}/>
			</Switch>
		</div>
	</Router>
	);
}
