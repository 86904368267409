import React from 'react'

export default function Binary() {
	const preventDefault = (event) => event.preventDefault();
	return (
	<div id="binary" className="animate__animated animate__fadeIn animate__slowerrr">
		<div className="animate__animated animate__fadeOut animate__delay-1s animate__slowerr">
			<img src="img/binary2.svg"/>							
		</div>
		<div className="animate__animated animate__fadeOut animate__delay-15s animate__slower wildcard">
			<img src="img/binary1.svg"/>							
		</div>
		<div className="animate__animated animate__fadeOut animate__delay-2s animate__slowerrr">
			<img src="img/binary3.svg"/>							
		</div>
	</div>
	);
}
