import React from 'react'
import ReactWOW from 'react-wow'
import Footer from './Footer'
import lax from '../lax.js'
import "../styles/Visual.scss";

export default function Visual() {
	React.useEffect(() => {
		window.scrollTo(0,0);
		lax.setup() // init

		const updateLax = () => {
			lax.update(window.scrollY)
			window.requestAnimationFrame(updateLax)
		}
   
		window.requestAnimationFrame(updateLax)				
	}, []);
	return (
	<div id="GraphicDesign" className="main animate__animated animate__fadeIn animate__delay-1s animate__slowerr">
		<p className="visual-bg">
			visual
		</p>
		<div className="list-container">
			<ul className="floating-list">
				<li id="a">
				</li>
				<li>	<div id="fish">
						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="leftToRight" alt="Design"/>						

						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="driftRight-500" alt="Design"/>
						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="leftToRight linger" alt="Design"/>						
						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="driftRight-300 swing" alt="Design"/>
						{/* <img src="img/fish.png"  class="lax lazyload" data-lax-preset="leftToRight" alt="Design"/> */}
						<img src="img/fish.png"  class="lax lazyload" data-lax-preset="driftRight-700 swing" alt="Design"/>
						<img src="img/fish.png"  class="lax lazyload" data-lax-preset="leftToRight lazy" alt="Design"/>
						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="leftToRight linger" alt="Design"/>
						<img src="img/fish.png"  class="lax lazyload" data-lax-preset="driftRight-450 lazy" alt="Design"/>
						<img src="img/fish.png"  class="lax lazyload" data-lax-preset="leftToRight" alt="Design"/>
						{/* <img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="leftToRight swing" alt="Design"/> */}
						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="leftToRight lazy" alt="Design"/>
						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="driftRight-1000 linger" alt="Design"/>
						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="leftToRight" alt="Design"/>						
						<img src="img/fish.png"  class="lax lazyload" data-lax-preset="driftRight-700" alt="Design"/>
						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="leftToRight" alt="Design"/>
					</div>
			
					<div id="fish">
						<img src="img/fish.png"  class="lax lazyload" data-lax-preset="leftToRight lazy" alt="Design"/>
						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="driftRight-1000 linger" alt="Design"/>
						<img src="img/fish.png"  class="lax lazyload" data-lax-preset="leftToRight" alt="Design"/>
						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="leftToRight linger" alt="Design"/>						
						<img src="img/fish.png"  class="lax baby lazyload" data-lax-preset="leftToRight linger" alt="Design"/>
						<img src="img/fish.png"  class="lax lazyload" data-lax-preset="driftRight-450 lazy" alt="Design"/>
						<img src="img/fish.png"  class="lax lazyload" data-lax-preset="leftToRight" alt="Design"/>
						<img src="img/fish.png"  class="lax lazyload" data-lax-preset="driftRight-700" alt="Design"/>
					</div>
				</li>
			</ul>
		</div>
		<div id="vendo">			
			<h1>
				<span id="v" className=" animate__animated animate__fadeInUp animate__delay-1s animate__slow">
					V
				</span>
				<span id="i" className=" animate__animated animate__fadeInUp animate__delay-1s animate__slower">
					i
				</span>
				<span id="s" className=" animate__animated animate__fadeInUp animate__delay-1s animate__slowerr">
					s
				</span>
				<span id="u" className=" animate__animated animate__fadeInUp animate__delay-1s animate__slower">
					u
				</span>
				<span id="a" className=" animate__animated animate__fadeInUp animate__delay-1s animate__slower">
					a
				</span>
				<span id="l" className=" animate__animated animate__fadeInUp animate__delay-1s animate__slow">
					l
				</span>
			</h1>						
			<div className="description animate__animated animate__fadeIn animate__delay-2s animate__slowerr">	
				<h3 className="company">
					Vendo Services
				</h3>
				<p>
				Swiss-Spanish leader in Digital Payments that grow the revenue of its Clients by using data and AI, with dynamic pricing in 23 languages and local currency.
				</p>
				<p>					
					I designed graphics to personalize User interfaces for their Products, mock-ups, logos, promotional material for social media, events, etc.					
				</p>
			</div>
			<ul className="animate__animated animate__fadeIn animate__delay-2s animate__slowerr">
				<li className="two-columns centered">
					<div className="fifty right">
						<p className="visual-item-name">
							Corporate logo
						</p>
					</div>
					<div className="fifty left">
						<div className="logo">
							<div>
								<img src="img/logos/vendo.svg" alt="Graphic Design"/>
							</div>
						</div>
					</div>
				</li>				
				<li className="two-columns centered" id="vpc">
					<div className="fifty right">
						<p className="visual-item-name">
							Vendo Partner Conference logo
						</p>
					</div>
					<div className="fifty left">
						<div className="logo">
							<div>
								<img src="img/logos/vpc.svg" alt="Graphic Design"/>
							</div>
						</div>
					</div>
				</li>		
			</ul>
			<div className="boing">
				<img id="boing-a" src="img/boing-a.svg" alt=""/>						
			</div>			
			<div id="vendo-media">
				<p className="visual-item-name">
					Marketing <span className="and"><img src="img/&.svg" alt="&"/></span> Social Media
				</p>						
				<div id="vendo-media-container">
					<ul id="left">
						<li>
							<noscript>
								<img src="img/vendo-aria-ai.jpg" alt="Graphic Design"/>
							</noscript>
							<img data-src="img/vendo-aria-ai.jpg" className="lazyload" alt="Graphic Design"/>
						</li>
						<li>
							<noscript>
								<img src="img/vendo-infographic.jpg" alt="Graphic Design"/>
							</noscript>
							<img data-src="img/vendo-infographic.jpg" class="lazyload" alt="Graphic Design"/>
						</li>
					</ul>
					<ul id="right">
						<li>
							<noscript>
								<img src="img/vpc-invite-a.jpg" alt="Graphic Design"/>
							</noscript>
							<img data-src="img/vpc-invite-a.jpg" className="lazyload" alt="Graphic Design"
							data-srcset="img/vpc-invite-a.jpg 1200w, img/vpc-invite-a-s.jpg 900w"
							data-sizes="(min-width: 900px) 1200px, 100vw"
							/>					
						</li>
						<li>
							<noscript>
								<img src="img/vendo-phoenix-invite.png" alt="Graphic Design"/>
							</noscript>
							<img data-src="img/vendo-phoenix-invite.png" className="lazyload" alt="Graphic Design"/>

						</li>
						<li>
							<noscript>
								<img src="img/vendo-avast-banner.jpg" alt="Graphic Design"/>
							</noscript>
							<img data-src="img/vendo-avast-banner.jpg" className="lazyload" alt="Graphic Design"
							data-srcset="img/vendo-avast-banner.jpg 1010w, img/vendo-avast-banner-s.jpg 900w"
							data-sizes="(min-width: 900px) 1010px, 100vw"
							/>					
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div id="anabel-instagram">
			< ReactWOW >
			<div className="description animate__animated animate__fadeIn animate__slower">	
				<h3>
					Anabel López
				</h3>
				<p><span class="highlight">Fashion Designer.</span> Graphic Design for Instagram.</p>
			</div>						
			<ul id="social-media" className="animate__animated animate__fadeIn animate__slowerr">
				<li>
					<noscript>
						<img src="img/ig6.jpg" alt="Web Design"/>
					</noscript>
					<img data-src="img/ig6.jpg" class="lazyload" alt="Web Design"
					data-srcset="img/ig6.jpg 1400w, img/ig6-s.jpg 900w"
					data-sizes="(min-width: 900px) 1400px, 100vw"
					/>
				</li>
				<li>
					<noscript>
						<img src="img/ig5.jpg" alt="Web Design"/>
					</noscript>
					<img data-src="img/ig5.jpg" class="lazyload" alt="Web Design"
					data-srcset="img/ig5.jpg 1400w, img/ig5-s.jpg 900w"
					data-sizes="(min-width: 900px) 1400px, 100vw"
					/>
				</li>
				<li>
					<noscript>
						<img src="img/ig.jpg" alt="Web Design"/>
					</noscript>
					<img data-src="img/ig.jpg" class="lazyload" alt="Web Design"
					data-srcset="img/ig.jpg 1400w, img/ig-s.jpg 900w"
					data-sizes="(min-width: 900px) 1400px, 100vw"
					/>
				</li>
				<li>
					<noscript>
						<img src="img/ig2.jpg" alt="Web Design"/>
					</noscript>
					<img data-src="img/ig2.jpg" class="lazyload" alt="Web Design"
					data-srcset="img/ig2.jpg 1400w, img/ig2-s.jpg 900w"
					data-sizes="(min-width: 900px) 1400px, 100vw"
					/>
				</li>
				<li>
					<noscript>
						<img src="img/ig3.jpg" alt="Web Design"/>
					</noscript>
					<img data-src="img/ig3.jpg" class="lazyload" alt="Web Design"
					data-srcset="img/ig3.jpg 1400w, img/ig3-s.jpg 900w"
					data-sizes="(min-width: 900px) 1400px, 100vw"
					/>
				</li>
				<li>
					<noscript>
						<img src="img/ig1.jpg" alt="Web Design"/>
					</noscript>
					<img data-src="img/ig1.jpg" class="lazyload" alt="Web Design"
					data-srcset="img/ig1.jpg 1400w, img/ig1-s.jpg 900w"
					data-sizes="(min-width: 900px) 1400px, 100vw"
					/>
				</li>
			</ul>															
			< /ReactWOW >		
			<div className="boing b">
				<img id="boing-b" src="img/boing-b.svg" alt=""/>						
			</div>					
		</div>				
		<div id="miscelaneous">
			<div id="checkboard">
				<noscript>
					<img src="img/checkboard.svg" alt="Web Design"/>
				</noscript>
				<img id="checkboard"src="img/checkboard.svg" alt="Web Design"/>
			</div>
			<ul>
				<li className="two-columns centered">
					<div className="fifty right">
						<img id="frida" src="img/frida.gif" class="lazyload" alt="Visual Design"/>
					</div>
					<div className="fifty left">
						<img src="img/al.png" alt="Visual Design"/>
					</div>
				</li>
			</ul>		
		</div>

		<Footer/>		

	</div>
	);
}