import React from 'react'
import {Link} from 'react-router-dom'
import ReactWOW from 'react-wow'
import lazySizes from 'lazysizes'
import lax from '../lax.js'
import Heading from './Heading'
import SlideshowA from './SlideshowA'
import SlideshowB from './SlideshowB'
import Footer from './Footer'

export default function HempTailor() {
	React.useEffect(() => {
		window.scrollTo(0,0);
		lax.setup() // init  

		const updateLax = () => {
			lax.update(window.scrollY)
			window.requestAnimationFrame(updateLax)
		}

		window.requestAnimationFrame(updateLax)				
	}, []);
	return ( 
	<div>
		<div id="UserInterfaceDesignAndFrontEndDevelopment" className="main animate__animated animate__fadeIn animate__delay-14s animate__slower">
			<div id="hemptailor">
				<Heading/>
				<div className="golden main-description-wrapper animate__animated animate__fadeIn animate__slower">
					<div className="golden-left animate__animated animate__fadeIn animate__delay-06s animate__slow">
						<div className="project-info">
							<div className="logo">
								<img src="img/logos/hemptailor.png" className="lazyload" alt="Hemptailor logo"/>
							</div>
							<span className="duration">2019</span>							
							<span className="role">#Fashion E-commerce</span>							
							<span className="role">UI/UX Design</span>		
							<span className="role">Consultant</span>							
						</div>
					</div>
					<div className="golden-right animate__animated animate__fadeIn animate__delay-07s animate__slow">
						<div className="description">	
							<h3>
								Hemp Tailor
							</h3>
							<p>Formerly known as HoodLamb. Dutch Designer of vegan hemp clothing.</p>
							<p className="wildcard-b">
								<span className="highlight">User Interface redesign of the Product page. </span>The goal was to improve the shopping experience, by greatly facilitating the Products purchase. Their expectation was to highlight the exceptional quality of their Products in a way that was more attractive and clear to the User, while maintaining the look and feel of the Hemp Tailor's experience.
							</p>
							<p className="paragraph-block">
								After a kick-off meeting, the research was done in two areas:
							</p>
							<ul>
								<li>
									<p>
										<span class="category-highlight highlight">Competitor analysis. </span>
										I analyzed how their competitors, and the leaders in the industry present and sell their Products online.
									</p>																			
								</li>
								<li>
									<p>
										<span class="category-highlight highlight">Shopify plugins. </span>
										I evaluated the Shopify App Store and identified the e-commerce plugins that could easily provide better functionalities to the Product page. 								
									</p>																			
								</li>
							</ul>
						</div>		
					</div>						
				</div>						
				<ul className="animate__animated animate__fadeIn animate__delay-1s animate__slowerr">
					<li className="two-columns centered sketch">
						<div className="fifty right">
							<div className="info-block">
								<p>
									<span className="product-name">Sketch</span>
								</p>
							</div>							
							<noscript>
								<img src="img/hemptailor-sketch.jpg" className="lazyload" alt="Hemptailor wireframe"/>																		
							</noscript>						
							<img src="img/hemptailor-sketch.jpg" className="lazyload" alt="Hemptailor wireframe"/>																		
							<img src="img/pen.png" className="pen lazyload" alt="Hemptailor wireframe"/>																		
						</div>
						<div className="fifty left">
							< ReactWOW >						
							<div className="description animate__animated animate__fadeIn animate__slow">	
								<p>
									Based on the research findings and using e-commerce best practices, I was able to sketch a solution, create wireframes and a few high fidelity mock-up versions.	
								</p>										
								<p>
									The redesign that I created was implemented in their Shopify store.
								</p>										

							</div>													
							< /ReactWOW >						
						</div>
					</li>
					<li className="wireframe">
						<div className="info-block">
							<p>
								<span className="product-name">Wireframe</span>
							</p>
						</div>
						<noscript>
							<img src="img/hemptailor-wireframe.png" className="lazyload" alt="Hemptailor wireframe"/>																		
						</noscript>
						<img data-src="img/hemptailor-wireframe.png" className="lazyload" alt="Web Design"
						data-srcset="img/hemptailor-wireframe.png 1440w, img/hemptailor-wireframe-s.png 900w"
						data-sizes="(min-width: 900px) 1440px, 100vw"
						/>
					</li>
					<li className="question">
						<div className="info-block">
							<p>
								<span className="product-name">Hi-fi Mock-ups</span>
							</p>
						</div>
						<noscript>
							<img src="img/hemptailor-comparison.jpg" className="lazyload" alt="Hemptailor User Interface Design"/>						</noscript>
							<img data-src="img/hemptailor-comparison.jpg" className="lazyload" alt="Web Design"
							data-srcset="img/hemptailor-comparison.jpg 1440w, img/hemptailor-comparison-s.jpg 900w"
							data-sizes="(min-width: 900px) 1440px, 100vw"
							/>
							< ReactWOW >			
							<img id="question-mark" className="golden-right lazyload animate__animated animate__zoomIn animate__slower" src="img/question-mark.png" alt="Hemptailor High fidelity Mock-ups"/>	
							< /ReactWOW >						
						</li>				
						<li className="two-columns ux">
							<div id="list-inner-wrapper">			
								<div className="fifty right">
									<div className="info-block">
										<p>
											<span className="product-name">Before</span>
										</p>
									</div>
									<noscript>
										<img src="img/hemp-tailor-product-page-ui-before.jpg" className="lazyload" alt="Vendo Personas"/>																		
									</noscript>						
									<img src="img/hemp-tailor-product-page-ui-before.jpg" className="lazyload" alt="Vendo Personas"/>																		
								</div>
								<div className="fifty left">
									<div className="info-block">
										<p>
											<span className="product-name">After</span>
										</p>
									</div>
									<noscript>
										<img src="img/hemp-tailor-product-page-ui-after.jpg" className="lazyload" alt="Vendo Personas"/>																		
									</noscript>						
									<img src="img/hemp-tailor-product-page-ui-after.jpg" className="lazyload" alt="Vendo Personas"/>																		
								</div>																
							</div>									
						</li>
						<li className="redesign">
							<div className="info-block">
								<p>
									<span className="product-name">Redesign</span>
								</p>
							</div>
							<noscript>
								<img src="img/hemp-tailor-product-page-ui-after.jpg" className="lazyload" alt="Vendo Personas"/>																		
							</noscript>						
							<img src="img/hemp-tailor-product-page-ui-after.jpg" className="lazyload" alt="Vendo Personas"/>																									
						</li>				
					</ul>
				</div>
			</div>
		
			<Footer/>
			<Link className="next-project"to= '/visual'>									
				Next project →
			</Link>
						
		</div>
		);
	}