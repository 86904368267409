import React from "react";

export default function Footer() {
  return (
    <div>
      <div id="thanku"></div>
      <div id="thanku-wrapper">
        <h3 className="animate__animated animate__fadeIn animate__delay-15s animate__slow">
          Thank you for your visit
        </h3>
        {/* <span id="hello" className="animate__animated animate__fadeIn animate__delay-15s animate__slower">
				Let's connect!
			</span> */}
        <a href="mailto:alopezrweb@gmail.com">Let's connect!</a>
      </div>
      <div
        id="top"
        className="animate__animated animate__fadeIn animate__delay-2s animate__slower"
      >
        <a href="#">
          <img src="img/up.svg" alt="Back to top" />
        </a>
      </div>
      <div id="website-info">
        <p>Website developed with ReactJS and Sass.</p>
      </div>
    </div>
  );
}
