import React from 'react'
import {Link} from 'react-router-dom'
import ReactWOW from 'react-wow'
import lazySizes from 'lazysizes'
import lax from '../lax.js'
import Heading from './Heading'
import SlideshowA from './SlideshowA'
import SlideshowB from './SlideshowB'
import Footer from './Footer'

export default function Adnovation() {
	React.useEffect(() => {
		window.scrollTo(0,0);
		lax.setup() // init  

		const updateLax = () => {
			lax.update(window.scrollY)
			window.requestAnimationFrame(updateLax)
		}

		window.requestAnimationFrame(updateLax)				
	}, []);
	return ( 
	<div>
		<div id="UserInterfaceDesignAndFrontEndDevelopment" className="main animate__animated animate__fadeIn animate__delay-14s animate__slower">
			<div id="adnovation">
				<Heading/>
				<div className="list-container">	
					<ul className="floating-list">
						<li id="adnovation-landing-page">
							<img src="img/adnovation-mobile-landing-page.png" className=" floating-mobile f lax" alt="Web Design"
							data-lax-translate-y="(vh*0.5) 360,(vh*-0.5) 0"
							data-lax-anchor="self"
							/>										
						</li>				
						<li id="adnovation-form-validation">
							<img src="img/adnovation-mobile-form-transparent-background.png" className=" floating-mobile g lax" alt="Web Design"
							data-lax-translate-y="(vh*0.5) 360,(vh*-0.5) 0"
							data-lax-anchor="self"
							/>								
						</li>													
						<li id="adnovation-login">
						</li>
						<li id="adnovation-outer-list">
						</li>								
						<li className="mobile smaller-screen">
						</li>						
					</ul>
				</div>
				<div className="golden main-description-wrapper animate__animated animate__fadeIn animate__slower">
					<div className="golden-left animate__animated animate__fadeIn animate__delay-06s animate__slow">
						<div className="project-info">
							<div className="logo">
								<img src="img/logos/adnovation.svg" className="lazyload" alt="Adnovation logo"/>
							</div>
							<span className="duration">2019</span>						
							<span className="role">#Online Marketing</span>						
							<span className="role">Front-End development</span>
							<span className="role">In-house employee</span>
						</div>
					</div>
					<div className="golden-right animate__animated animate__fadeIn animate__delay-07s animate__slow">
						<div className="description">	
							<h3>
								Adnovation
							</h3>
							<p>Dutch SAAS for online Marketing campaigns management.</p>
							<p>
								I developed their Products landing pages and revamped their Products interfaces.
							</p>
						</div>
					</div>						
				</div>
				<ul className="animate__animated animate__fadeIn animate__delay-1s animate__slowerr">
					<li className="adnovation-landing-page">
						<div className="info-block">
							<p>
								<span className="company-name">Adnovation</span>
								<span className="dash"> - </span>
								<span className="product-name">Landing Page</span>
								<span className="dash"> - </span>
								<span className="work">Front-End Development</span>
								<span className="not-my-design">* Client's Design</span>
							</p>
						</div>
						<noscript>
							<img src="img/adnovation-landing-page.jpg" alt="Web Development"/>
						</noscript>
						<noscript>
							<img src="img/adnovation-mobile-landing-page.png" id="floater" alt="Web Development"/>
						</noscript>
						<img data-src="img/adnovation-landing-page.jpg" className="lazyload" alt="Web Development"
						data-srcset="img/adnovation-landing-page.jpg 1440w, img/adnovation-landing-page-s.jpg 900w"
						data-sizes="(min-width: 900px) 1440px, 100vw"
						/>
					</li>				
					<li id="adnovation-form-validation">
						<div className="info-block">
							<p>
								<span className="company-name">Adnovation</span>
								<span className="dash"> - </span>
								<span className="product-name">Product Form</span>
								<span className="dash"> - </span>
								<span className="work">Front-End Development</span>
								<span className="not-my-design">* Client's Design</span>
							</p>
						</div>			
						<noscript>
							<img src="img/adnovation-form-validation.jpg" alt="Web Development"/>
						</noscript>
						<img data-src="img/adnovation-form-validation.jpg" className="lazyload" alt="Web Development"
						data-srcset="img/adnovation-form-validation.jpg 1440w, img/adnovation-form-validation-s.jpg 900w"
						data-sizes="(min-width: 900px) 1440px, 100vw"										
						/>
					</li>													
					<li id="adnovation-login">
						<div className="info-block">
							<p>
								<span className="company-name">Adnovation</span>
								<span className="dash"> - </span>
								<span className="product-name">Login Page</span>
								<span className="dash"> - </span>
								<span className="work">Front-End Development</span>
								<span className="not-my-design">* Client's Design</span>
							</p>
						</div>
						<noscript>
							<img src="img/adnovation-login.jpg" alt="Web Development"/>
						</noscript>
						<img data-src="img/adnovation-login.jpg" className="lazyload" alt="Web Development"
						data-srcset="img/adnovation-login.jpg 1440w, img/adnovation-login-s.jpg 900w"
						data-sizes="(min-width: 900px) 1440px, 100vw"
						/>
					</li>
					<li id="adnovation-outer-list">
						<ul id="adnovation-inner-list">
							<li className="mobile smaller-screen">
								<div className="info-block">
									<p>
										<span className="company-name">Adnovation</span>
										<span className="dash"> - </span>
										<span className="product-name">Landing Page</span>
										<span className="dash"> - </span>
										<span className="work">Front-End Development</span>
										<span className="not-my-design">* Client's Design</span>
									</p>
								</div>
								<noscript>
									<img src="img/adnovation-mobile-landing-page.png" alt="Web Design"/>
								</noscript>
								<div>							
									<img data-src="img/adnovation-mobile-landing-page.png" className="lazyload" alt="Web Design"/>
								</div>
							</li>								
							<li className="mobile smaller-screen">
								<div className="info-block">
									<p>
										<span className="company-name">Adnovation</span>
										<span className="dash"> - </span>
										<span className="product-name">Product Form</span>
										<span className="dash"> - </span>
										<span className="work">User Interface Design & Front-End Development</span>
									</p>
								</div>								
								<noscript>
									<img src="img/adnovation-mobile-form-transparent-background.png" alt="Web Design"/>
								</noscript>
								<div>							
									<img data-src="img/adnovation-mobile-form-transparent-background.png" className="lazyload" alt="Web Design"/>
								</div>
							</li>		
						</ul>			
					</li>					
				</ul>
			</div>
		</div>
		
		<Footer/>

		<Link className="next-project"to= '/vendo-services'>						
			Next project →
		</Link>
						
	</div>
	);
}