import React, {useEffect, useRef, useState} from "react";
import "../styles/Slideshow.css";

const Slideshow = ({
	images = [],
	withLoop = true,
	autoPlay = false,
	autoPlaySeconds = 1,
}) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [isFirst, setIsFirst] = useState(true);
	const [isLast, setIsLast] = useState(false);
	const [isPlaying, setIsPlaying] = useState(autoPlay);
	useEffect(() => {
		setIsFirst(currentSlide === 0);
		setIsLast(currentSlide === images.length - 1);
	}, [currentSlide, images.length]);

	const autoPlayRef = useRef();

	useEffect(() => {
		autoPlayRef.current = next;
	});

	useEffect(() => {
		let interval;
		const play = () => autoPlayRef.current();
		if (isPlaying) {
			if (!withLoop && isLast) {
				setIsPlaying(false);
				return clearInterval(interval);
			}
			interval = setInterval(play, autoPlaySeconds * 1000);
		}
		return () => clearInterval(interval);
	}, [isPlaying, autoPlaySeconds, withLoop, isLast]);

	const next = (stopAutoPlay = false) => {
		if (stopAutoPlay)
		setIsPlaying(false);
		if (currentSlide === images.length - 1)
		return setCurrentSlide(0);
		setCurrentSlide(currentSlide + 1);
	};

	const prev = (stopAutoPlay = false) => {
		if (stopAutoPlay) {
			setIsPlaying(false);
		}
		if (currentSlide === 0) {
			setCurrentSlide(images.length - 1);
		} else {
			setCurrentSlide(currentSlide - 1);
		}
	};
	return (
	<>
	<div id="slide-wrapper">
		<img src={images[currentSlide]} alt=""/>
	</div>
	<div className="slideshow-navigation">
		{(withLoop || !isLast) && (
			<div className="play-pause"
			onClick={() => setIsPlaying(!isPlaying)}>{isPlaying ? 'PAUSE' : 'PLAY'}</div>
			)}
			{(withLoop || !isFirst) && (
				<span className="arrows" onClick={() => prev(true)}>
					<img src="img/back.svg" alt="back button"/>
					<span>after</span>
				</span>
				
				)}
				{(withLoop || !isLast) && (
					<span className="arrows" onClick={() => next(true)}>
						<img src="img/show.svg" alt="show button"/>
						<span>before</span>
					</span>
					)}
				</div>
				<div id="slides-count">{`${currentSlide + 1} of ${images.length}`}</div>
				</>
				);
			};

			export default Slideshow;
