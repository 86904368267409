import React from 'react'
import ReactWOW from 'react-wow'
import lazySizes from 'lazysizes'
import lax from '../lax.js'
import Heading from './Heading'
import SlideshowA from './SlideshowA'
import SlideshowB from './SlideshowB'
import Footer from './Footer'
import HomeNav from './HomeNav'

export default function UiUxAndFe() {
	React.useEffect(() => {
		window.scrollTo(0,0);
		lax.setup() // init  

		const updateLax = () => {
			lax.update(window.scrollY)
			window.requestAnimationFrame(updateLax)
		}

		window.requestAnimationFrame(updateLax)				
	}, []);
	return ( 
	<div>
		<Heading/>
		<HomeNav/>
	</div>
	);
}