import React from 'react'
import {Link} from 'react-router-dom'

export default function Header() {
	const preventDefault = (event) => event.preventDefault();
	return (
	<header>
		<div id="header-container">
			<div id="name" className="animate__animated animate__fadeInUpX animate__delay-05s">
				<a href="#">
					Alberto L&oacute;pez
					{/* <span class="beta">beta</span> */}
				</a>
			</div>
			<ul id="contact" className="animate__animated animate__fadeIn animate__delay-07s animate__slowerr">
				<li className="underline-uife">
					<Link to= '/ui-ux-fe'>
						<a href="#" title="UI/UX Design & Front-End Development">UI/UX <span class="and"><img src="img/&.svg" alt="&"/></span> FE</a>      
					</Link>					
				</li>		
				<li className="underline-gd">
					<Link to= '/visual'>
						<a href="https://elgalloweb.com/graphic-design"  target="_blank" title="Visual Design">VISUAL</a>      
					</Link>
				</li>							
			</ul>
			{/* <a className="contact animate__animated animate__fadeIn animate__delay-18s animate__slow" href="mailto:alopezrweb@gmail.com">Let's talk</a>       */}
			<a className="contact animate__animated animate__fadeIn animate__delay-18s animate__slow" href="mailto:alopezrweb@gmail.com">Email me</a>      
		</div>
	</header>
	);
}
