import React from 'react'
import {Link} from 'react-router-dom'
import Binary from './Binary'

export default function HomeNav() {
	const preventDefault = (event) => event.preventDefault();
	return (
	<nav>
		<ul>
			<li id="vendo" className="animate__animated animate__fadeIn animate__delay-14s animate__slow">
				<Link to= '/vendo-services'>
					<div className="project-info">
						<div className="logo">
							<img src="img/logos/vendo.svg" className="lazyload" alt="Vendo Services logo"/>							
						</div>
						<span className="duration">2007 - 2018 | 2020</span>								
						<p>
						Swiss-Spanish leader in Digital Payments that grow the revenue of its Clients by using data and AI.
						</p>
						<p>
							I designed and developed personalized and customizable User interfaces for their Products, with a strong focus on increasing the Conversion rate. 
						</p>
						<div class="role-container">
							<span className="role">#Financial Technology</span>
							<span className="role">UI/UX Design</span>		
							<span className="role">Web Design</span>
							<span className="role">Front-End Development</span>
							<span className="role">Visual & Graphic Design</span>
							<span className="role">In-house employee</span>
						</div>
					</div>
				</Link>
			</li>							
			<li id="hemptailor" className=" animate__animated animate__fadeIn animate__delay-15s animate__slow">
				<Link to= '/hemp-tailor'>						
					<div className="project-info">
						<div className="logo">
							<img src="img/logos/hemptailor.png" className="lazyload" alt="Hemptailor logo"/>
						</div>
						<span className="duration">2019</span>							
						<p>
							Formerly known as HoodLamb. Dutch Designer of vegan hemp clothing.
						</p>
						<p>
							I redesigned the User interface of their Product page.
						</p>
						<div class="role-container">
							<span className="role">#Fashion E-commerce</span>							
							<span className="role">UI/UX Design</span>		
							<span className="role">Consultant</span>							
						</div>
					</div>
				</Link>
			</li>												
		</ul>			

		<Binary/>

	</nav>
	);
}